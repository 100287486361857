import { createRouter, createWebHistory } from 'vue-router'
import type { RouteRecordRaw } from 'vue-router'
import localCache from '@/utils/cache'

const routes: RouteRecordRaw[] = [
  // {
  //   path: '/',
  //   redirect: '/home'
  // },

  {
    path: '/',
    redirect: '/home'
  },
  {
    path: '/login',
    name: 'login',
    // component: () => import('@/views/login/login_old.vue'),
    component: () => import('@/views/login/login.vue'),
    meta: {
      title: '登录'
    }
  },
  {
    path: '/home', //首dmun
    name: 'home',
    component: () => import('@/views/main/home/index.vue'),
    meta: {
      title: '勋然体育-智慧体育'
    }
  },
  {
    path: '/studentList', //首dmun
    name: 'studentList',
    component: () => import('@/views/main/student/list.vue'),
    meta: {
      title: '学生信息'
    }
  },
  {
    path: '/createStudent', //首dmun
    name: 'createStudent',
    component: () => import('@/views/main/student/create.vue'),
    meta: {
      title: '编辑个人信息'
    }
  },
  {
    path: '/studentDetail', //首dmun
    name: 'studentDetail',
    component: () => import('@/views/main/student/detail.vue'),
    meta: {
      title: '学生基本信息'
    }
  },
  {
    path: '/cameraFace', //首dmun
    name: 'cameraFace',
    component: () => import('@/views/main/student/cameraFace.vue'),
    meta: {
      title: '人脸照片录入'
    }
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'notFound',
    component: () => import('@/views/not-found/not-found.vue')
  }
]

const router = createRouter({
  routes,
  history: createWebHistory()
})

// 导航守卫
router.beforeEach((to) => {
  if (to.path !== '/login') {
    const token = localCache.getCache('token')
    if (!token) {
      return '/login'
    }
  }

  console.log(router.getRoutes())
  console.log(to) // route对象

  if (to.path === '/main') {
    const token = localCache.getCache('token')
    if (!token) {
      return '/login'
    }
    return ''
  }
})

export default router
