import { Module } from 'vuex'

import { IStudentState } from './types'
import { IRootState } from '../../types'

import { IStudent } from './types'

import {
  createStudentRequest,
  editStudentRequest,
  getStudentListRequest,
  getStudentByIdRequest,
  uploadAvatarRequest,
  getGrades,
  getGroups
} from '@/service/main/student'

import {
  faceCollectStartRequest,
  cameraFaceStopRequest
} from '@/service/main/ai'

const studentModule: Module<IStudentState, IRootState> = {
  namespaced: true,
  state() {
    return {
      selectedStudentId: 0,
      lists: [],
      listsTotal: '0'
    }
  },
  getters: {
    listsData(state) {
      return state.lists
    },
    listsTotal(state) {
      return state.listsTotal === '0' ? 0 : state.listsTotal
    },
    selectedStudentIdData(state) {
      return state.selectedStudentId
    }
  },
  mutations: {
    selectedStudentId(state, studentId: number) {
      state.selectedStudentId = studentId
    },
    changeLists(state, list) {
      state.lists = list
    },
    changeListsTotal(state, count) {
      state.listsTotal = count
    }
  },
  actions: {
    async faceCollectStartRequest({ commit, dispatch }, payload: any) {
      const result = await faceCollectStartRequest(payload)
      return result
    },
    async cameraFaceStopRequest({ commit, dispatch }, payload: any) {
      const result = await cameraFaceStopRequest()
      return result
    },
    async uploadStudentAvatarRequest({ commit }, data: any[]) {
      const result = await uploadAvatarRequest(data)
      return result
    },
    selectedStudentIdAction({ commit, dispatch }, payload: any) {
      commit('selectedStudentId', payload)
      return true
    },
    async createStudentRequest({ commit }, data: IStudent) {
      const result = await createStudentRequest(data)
      return result
    },
    async editStudentRequest({ commit }, data: IStudent) {
      const result = await editStudentRequest(data)
      return result
    },

    async getStudentByIdRequest({ commit }, data: IStudent) {
      const result = await getStudentByIdRequest(data)
      return result.data
    },
    async getStudentListRequest({ commit }, queryInfo: any[]) {
      const result = await getStudentListRequest(queryInfo)
      commit('changeLists', result.data?.items)
      commit('changeListsTotal', result.data?.total)
    },
    async getGrades({ commit, dispatch }, payload: any) {
      const result = await getGrades()
      return result.data
    },
    async getGroups({ commit }) {
      const result = await getGroups()
      return result.data
    }
  }
}

export default studentModule
