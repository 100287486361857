import { defineComponent } from 'vue';
export default defineComponent({
  name: 'App',
  props: {
    name: {
      type: String
    }
  },
  setup: function setup() {
    return {};
  }
});