import { App } from 'vue'
import 'vant/lib/index.css'
import {
   Form,
    Field,
    CellGroup,
    Button,
    NavBar,
    List,
    Cell,
    Row,
    Popup,
    Cascader,
    Uploader,
    Toast
   } from 'vant'

const component = [
  Form,
  Field,
  CellGroup,
  Button,
  NavBar,
  List,
  Cell,
  Row,
  Popup,
  Cascader,
  Uploader,
  Toast
]


export default function (app: App): void {
  const components = [ ...component]
  for (const component of components) {
    app.component(component.name, component)
  }
}