import { Module } from 'vuex'

import { accountLoginRequest, requestSchoolInfo } from '@/service/login/login'
import localCache from '@/utils/cache'
import router from '@/router'

import { IAccount } from '@/service/login/type'
import { ILoginState } from './types'
import { IRootState } from '../types'

const loginModule: Module<ILoginState, IRootState> = {
  namespaced: true,
  state() {
    return {
      token: '',
      schoolTitle: ''
    }
  },
  getters: {
    schoolTitleData(state) {
      return state.schoolTitle
    }
  },
  mutations: {
    changeToken(state, token: string) {
      state.token = token
    },

    changeSchoolTitle(state, schoolTitle: any) {
      state.schoolTitle = schoolTitle
    }
  },
  actions: {
    async accountLoginAction({ commit, dispatch }, payload: IAccount) {
      let userToken: string
      await accountLoginRequest(payload)
        .then((loginResult) => {
          console.log('loginResult', loginResult)
          const { token } = loginResult.data
          userToken = token
          commit('changeToken', token)
          localCache.setCache('token', token)
        })
        .catch((err) => {
          console.log('accountLoginRequest', err)
        })

      const schoolInfoResult = await requestSchoolInfo()
      const schoolInfo = schoolInfoResult.data
      commit('changeSchoolTitle', schoolInfo.title)
      localCache.setCache('schoolTitle', schoolInfo.title)
      // 4.跳到首页
      router.push('/')
    },

    loadLocalLogin({ commit, dispatch }) {
      const token = localCache.getCache('token')
      if (token) {
        commit('changeToken', token)

        const schoolTitle = localCache.getCache('schoolTitle')
        if (schoolTitle) {
          commit('changeSchoolTitle', schoolTitle)
        }
      }
    },
    async accountLoginOutAction({ commit, dispatch }, payload: IAccount) {
      localCache.deleteCache('token')
      localCache.deleteCache('schoolTitle')
      commit('changeToken', '')
      commit('changeSchoolTitle', '')
      return true
    }
  }
}

export default loginModule
