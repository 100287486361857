import qs from 'qs'
import hyRequest from '../index'
import { IDataType } from '../types'
import { IStudent } from '../../store/main/student/types'

enum StudentAPI {
  CREATE = '/api/student/create',
  EDIT = '/api/student/edit',
  GET_LISTS = '/api/student/list',
  GET_BY_ID = '/api/student/getById',
  UPLOAD_AVATAR = '/api/student/avatar',
  GET_GRADES = '/xunran/grades',
  GET_GROUPS = '/xunran/groups'
}

export function createStudentRequest(data: IStudent) {
  return hyRequest.post<IDataType>({
    url: StudentAPI.CREATE,
    data: qs.stringify(data)
  })
}
export function editStudentRequest(data: IStudent) {
  return hyRequest.post<IDataType>({
    url: StudentAPI.EDIT,
    data: qs.stringify(data)
  })
}
export function uploadAvatarRequest(data: any) {
  return hyRequest.post<IDataType>({
    url: StudentAPI.UPLOAD_AVATAR,
    data: qs.stringify(data)
  })
}
export function getStudentListRequest(data: any) {
  return hyRequest.get<IDataType>({
    url: StudentAPI.GET_LISTS,
    params: data
  })
}
export function getStudentByIdRequest(data: any) {
  return hyRequest.get<IDataType>({
    url: StudentAPI.GET_BY_ID,
    params: data
  })
}
export function getGrades() {
  return hyRequest.get<IDataType>({
    url: StudentAPI.GET_GRADES
  })
}
export function getGroups() {
  return hyRequest.get<IDataType>({
    url: StudentAPI.GET_GROUPS
  })
}
