import { createApp } from 'vue'
import { globalRegister } from './global'

import 'normalize.css'
import './assets/css/index.less'
import App from './App.vue'
import 'amfe-flexible/index.js'

import fastClick from 'fastclick'
// fastClick.attach(document.body)

import router from './router'
import store from './store'
import { setupStore } from './store'

import Vconsole from 'vconsole';
if(window.appConfig.env == 'dev'){
  new Vconsole();
}

const app = createApp(App)

app.use(globalRegister)
app.use(store)
app.use(require('vue-wechat-title'))

setupStore()

app.use(router)

app.mount('#app')
