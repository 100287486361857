import qs from 'qs'
import hyRequest from '../index'
import { IDataType } from '../types'

//头像采集
const CAMERA_FACE_COLLECT = '/api/'+window.appConfig.cameraName+'/face_collect'
//运动项目-深蹲
const CAMERA_SQUAT = '/api/'+window.appConfig.cameraName+'/squat/action'
//运动项目-立定跳远
const CAMERA_JUMP = '/api/'+window.appConfig.cameraName+'/jump/action'
//运动项目-仰卧起坐
const CAMERA_SIT_UP = '/api/'+window.appConfig.cameraName+'/sitUp/action'
//运动项目-引体向上
const CAMERA_PULL_UP = '/api/'+window.appConfig.cameraName+'/pullUp/action'
//运动项目-俯卧撑
const CAMERA_PUSH_UP = '/api/'+window.appConfig.cameraName+'/pushUp/action'

//区域设置
const CAMERA_AREA_FEED = '/api/'+window.appConfig.cameraName+'/area/action'
//区域坐标设置
const CAMERA_AREA_SETTING = '/api/'+window.appConfig.cameraName+'/area/settings'

// 头像采集 start
//拍照
export function faceCollectStartRequest(data:any) {
  const action = {
    action: 'photo'
  }
  const params = {
    ...action,...data
  }
  return hyRequest.post<IDataType>({
    url: CAMERA_FACE_COLLECT,
    data: qs.stringify(params)
  })
}
//结束调用摄像头
export function cameraFaceStopRequest() {
  const data = {
    action: 'stop'
  }
  return hyRequest.post<IDataType>({
    url: CAMERA_FACE_COLLECT,
    data: qs.stringify(data)
  })
}
// 头像采集 end

//运动项目start
export function cameraSquatStartRequest() {
  const data = {
    action: 'start'
  }
  return hyRequest.post<IDataType>({
    url: CAMERA_SQUAT,
    data: qs.stringify(data)
  })
}
//结束调用摄像头
export function cameraSquatStopRequest() {
  const data = {
    action: 'stop'
  }
  return hyRequest.post<IDataType>({
    url: CAMERA_SQUAT,
    data: qs.stringify(data)
  })
}
export function cameraJumpStartRequest() {
  const data = {
    action: 'start'
  }
  return hyRequest.post<IDataType>({
    url: CAMERA_JUMP,
    data: qs.stringify(data)
  })
}
export function cameraJumpStopRequest() {
  const data = {
    action: 'stop'
  }
  return hyRequest.post<IDataType>({
    url: CAMERA_JUMP,
    data: qs.stringify(data)
  })
}
export function cameraSitUpStartRequest() {
  const data = {
    action: 'start'
  }
  return hyRequest.post<IDataType>({
    url: CAMERA_SIT_UP,
    data: qs.stringify(data)
  })
}
export function cameraSitUpStopRequest() {
  const data = {
    action: 'stop'
  }
  return hyRequest.post<IDataType>({
    url: CAMERA_SIT_UP,
    data: qs.stringify(data)
  })
}
export function cameraPullUpStartRequest() {
  const data = {
    action: 'start'
  }
  return hyRequest.post<IDataType>({
    url: CAMERA_PULL_UP,
    data: qs.stringify(data)
  })
}
export function cameraPullUpStopRequest() {
  const data = {
    action: 'stop'
  }
  return hyRequest.post<IDataType>({
    url: CAMERA_PULL_UP,
    data: qs.stringify(data)
  })
}
export function cameraPushUpStartRequest() {
  const data = {
    action: 'start'
  }
  return hyRequest.post<IDataType>({
    url: CAMERA_PUSH_UP,
    data: qs.stringify(data)
  })
}
export function cameraPushUpStopRequest() {
  const data = {
    action: 'stop'
  }
  return hyRequest.post<IDataType>({
    url: CAMERA_PUSH_UP,
    data: qs.stringify(data)
  })
}

//运动项目end

///区域设置start
//结束调用摄像头
export function cameraAreaStopRequest() {
  const data = {
    action: 'stop'
  }
  return hyRequest.post<IDataType>({
    url: CAMERA_AREA_FEED,
    data: qs.stringify(data)
  })
}
///区域设置end

//区域坐标设置
export function cameraAreaSettingsRequest(params) {
  return hyRequest.post<IDataType>({
    url: CAMERA_AREA_SETTING,
    data: qs.stringify(params)
  })
}
export function getCameraAreaSettingsRequest(data) {
  return hyRequest.get<IDataType>({
    url: CAMERA_AREA_SETTING,
    params: data
  })
}
