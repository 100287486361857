import hyRequest from '../index'
import qs from 'qs'
import { IAccount, ILoginResult } from './type'
import { IDataType } from '../types'

enum LoginAPI {
  AccountLogin = '/api/login',
  LoginSchoolInfo = '/api/school_info'
}

export function accountLoginRequest(account: IAccount) {
  return hyRequest.post<IDataType<ILoginResult>>({
    url: LoginAPI.AccountLogin,
    data: qs.stringify(account)
  })
}

export function requestSchoolInfo() {
  return hyRequest.get<IDataType>({
    url: LoginAPI.LoginSchoolInfo,
    showLoading: false
  })
}
